<template>
  <modal
    v-bind="modalData"
    @close="prepareClose"
  >
    <page :data="pageData" :is-child="true">
      <template v-for="(s, i) in $scopedSlots" v-slot:[i]="scopedProps">
        <slot :name="i" v-bind="scopedProps"></slot>
      </template>
    </page>
  </modal>
</template>

<script>
import BaseCMSPage from './BaseCMSPage'
export default {
  extends: BaseCMSPage,
  components: {
    Modal: () => import('@/components/vaddress/common/Modal'),
    Page: () => import('@/components/vaddress/cmspages/Page')
  },
  props: {
    show: Boolean,
    url: String
  },
  data () {
    return {
      initOnMounted: false,
      cacheContent: false
    }
  },
  methods: {
    prepareClose () {
      if (!this.cacheContent) {
        this.$store.dispatch('webcontent/remove', this.url)
      }
      this.close()
    },
    close () {
      this.$emit('close')
    }
  },
  computed: {
    config () {
      return {
        ...this.response.Config || {},
        SubmitButton: false,
        CloseButton: false
      }
    },
    apiPath () {
      return this.url
    },
    modalData () {
      return {
        title: (this.config.Page || {}).Title,
        show: this.show,
        submitButton: this.config.SubmitButton,
        closeButton: this.config.CloseButton,
        fullscreen: (this.config.Page || {}).FullScreen,
        class: (this.config.Page || {}).Class
      }
    }
  },
  watch: {
    show: {
      immediate: true,
      handler (v) {
        if (v) this.init()
      }
    }
  }
}
</script>

<style>

</style>
