<template>
<modal-cms-page class="modal-bg-gray" v-bind="modalProps(modalKey)" @close="closeModal(modalKey)">
  <template #cmslefttype(vaddress-listitem)="{ data: d}">
    <list-item
      v-if="d.Data.Id === 'status'"
      :data="d.Data"
    >
    <template #righttitle>
      <b-tag :type="d.Data.Status.Type">{{d.Data.Status.Text}}</b-tag>
    </template>
    </list-item>
    <list-item v-else :data="d.Data"></list-item>
  </template>
</modal-cms-page>
</template>

<script>
import ModalCmsPage from '../../base/ModalCMSPage'
export default {
  components: {
    ModalCmsPage,
    ListItem: () => import('@/components/vaddress/ListItem')
  },
  props: {
    modalKey: String
  }
}
</script>
